// Is not depend on environment
// 
const GB_FUNDRAISIN_GCOMPANIES = [
  {
    name: "Beatson Cancer Charity",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/beatson_cancer_charity.png",
    descriptionKey: "",

    beneficiaryAccountId: "1373523",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1373523",
  },
  {
    name: "Birmingham Hospice",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/birmingham_hospice.png",
    descriptionKey: "",

    beneficiaryAccountId: "1547155",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1547155",
  },
  {
    name: "Catherine McEwan Foundation",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/catherine_mcewan_foundation.png",
    descriptionKey: "",

    beneficiaryAccountId: "1505947",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1505947",
  },
  {
    name: "Children With Cancer UK",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/children_with_cancer_uk.png",
    descriptionKey: "",

    beneficiaryAccountId: "1373029",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1373029",
  },
  {
    name: "Encephalitis International",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/encephalitis_international.png",
    descriptionKey: "",

    beneficiaryAccountId: "1534223",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1534223",
  },
  {
    name: "INSPIRE",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/inspire.png",
    descriptionKey: "",

    beneficiaryAccountId: "1388523",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1388523",
  },
  {
    name: "Making the Leap",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/making_the_leap.png",
    descriptionKey: "",

    beneficiaryAccountId: "1373669",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1373669",
  },
  {
    name: "Mental Health Matters",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/mental_health_matters.png",
    descriptionKey: "",

    beneficiaryAccountId: "1374301",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1374301",
  },
  {
    name: "Rethink Mental Illness",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/rethink_mental_illness.png",
    descriptionKey: "",

    beneficiaryAccountId: "1372724",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1372724",
  },
  {
    name: "Spina Bifida Hydrocephalus Scotland",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/spina_bifida_hydrocephalus_scotland.png",
    descriptionKey: "",

    beneficiaryAccountId: "1546737",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1546737",
  },
  {
    name: "Teenage Cancer Trust",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/teenage_cancer_trust.png",
    descriptionKey: "",

    beneficiaryAccountId: "1374359",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1374359",
  },
  {
    name: "The Last Kiss Foundation",
    hasImage: true,
    logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/the_last_kiss_foundation.png",
    descriptionKey: "",

    beneficiaryAccountId: "1534460",
    eventCampaingId: "14142",
    waitForCompletion: true,
    gtmId: "1534460",
  },
  {
    name: "other",
    hasImage: false,
    logo: "",
    descriptionKey: "charity_select_other_later",

    beneficiaryAccountId: null,
    eventCampaingId: "14142",
    waitForCompletion: false,
    gtmId: "Choice",
  },
];

const config = {
  local: {
    us: {
      // Red Cross
      // https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/red_cross.png
      fundraisingCompanies: [
        {
          name: "American Cancer Society",
          hasImage: true,
          logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/cancer.png",
          descriptionKey: "",

          beneficiaryAccountId: "1370254",
          eventCampaingId: "13250",
          waitForCompletion: true,
          gtmId: "1370254",
        },
        {
          name: "other",
          hasImage: false,
          logo: "",
          descriptionKey: "charity_select_other_later",

          beneficiaryAccountId: null,
          eventCampaingId: "13365",
          waitForCompletion: false,
          gtmId: "Choice",
        },
      ],
    },
    eu: {
      fundraisingCompanies: GB_FUNDRAISIN_GCOMPANIES,
    },
  },
  staging: {
    us: {
      fundraisingCompanies: [
        {
          name: "American Cancer Society",
          hasImage: true,
          logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/cancer.png",
          descriptionKey: "",

          beneficiaryAccountId: "1370254",
          eventCampaingId: "13250",
          waitForCompletion: true,
          gtmId: "1370254",
        },
        {
          name: "other",
          hasImage: false,
          logo: "",
          descriptionKey: "charity_select_other_later",

          beneficiaryAccountId: null,
          eventCampaingId: "13365",
          waitForCompletion: false,
          gtmId: "Choice",
        },
      ],
    },
    eu: {
      fundraisingCompanies: GB_FUNDRAISIN_GCOMPANIES,
    },
  },
  production: {
    us: {
      fundraisingCompanies: [
        {
          name: "American Cancer Society",
          hasImage: true,
          logo: "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/affiliate_logos/cancer.png",
          descriptionKey: "",

          beneficiaryAccountId: "1370254",
          eventCampaingId: "13250",
          waitForCompletion: true,
          gtmId: "1370254",
        },
        {
          name: "other",
          hasImage: false,
          logo: "",
          descriptionKey: "charity_select_other_later",

          beneficiaryAccountId: null,
          eventCampaingId: "13365",
          waitForCompletion: false,
          gtmId: "Choice",
        },
      ],
    },
    gb: {
      fundraisingCompanies: GB_FUNDRAISIN_GCOMPANIES,
    },
  },
};

export const CHECKOUT_CONFIG = config[process.env.VUE_APP_ENV];

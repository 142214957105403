import { mapGetters, mapActions } from "vuex";

const APP_ENV = process.env.VUE_APP_ENV;

export default {
  computed: {
    ...mapGetters([
      "isAuthorized",
      "userData",
      "subscriptionStatus",
      "headerLinks",
    ]),
    userCountry() {
      return this.userData?.spartan_country_code?.toUpperCase();
    },
    showSpartanPlusLink() {
      const spartanPlusCountries = ["US"];
      let { country } = this.$route.query;

      if (
        !this.isAuthorized &&
        spartanPlusCountries.includes(country?.toUpperCase())
      )
        return true;

      if (
        this.isAuthorized &&
        spartanPlusCountries.includes(this.userCountry) &&
        !this.subscriptionStatus
      )
        return true;

      return false;
    },
  },
  methods: {
    ...mapActions(["logOut"]),
    async userSignOut() {
      await this.logOut();

      let logoutUrl = "https://www.spartan.com/account/logout";

      if (APP_ENV === "local") return this.$router.push({ name: "formLogin" });
      if (APP_ENV === "staging")
        logoutUrl = "https://staging.spartan.com/account/logout";

      window.location = logoutUrl;
    },
  },
  mounted() {},
};

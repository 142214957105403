import Vue from "vue";
import Vuex from "vuex";

import profile from "./modules/profile";
import events from "./modules/events";
import results from "./modules/results";
import subscription from "./modules/subscription";
import tropaion from "./modules/tropaion";
import tickets from "./modules/tickets";
import teams from "./modules/teams/index";
import auth from "./modules/auth";
import shop from "./modules/shop";
import feed from "./modules/feed";
import cart from "./modules/cart";
import geocoding from "./modules/geocoding";
import checkout from "./modules/checkout";
import { getCookie } from "@/cookie";
import config from "@/config";

const API = config.apiGate;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    notification: {
      // supported types: success, warning
      type: "",
      text: "",
      isVisible: false,
      canClose: false,
      body: "",
      data: {},
    },
    abuseWords: [],
    overlay: {
      country: false,
    },

    unassignedTicketsModal: false,
  },
  mutations: {
    setNotification(
      state,
      { type, text, isVisible, canClose, closeTimeout, body, data }
    ) {
      // available types: warning, success
      state.notification.type = type;
      state.notification.text = text;
      state.notification.isVisible = isVisible;
      state.notification.canClose = canClose;
      // notification view type, if empty using default body
      state.notification.body = body;
      // additional data, can be anything
      state.notification.data = { ...data };

      if (closeTimeout)
        setTimeout(() => {
          state.notification.isVisible = false;
        }, closeTimeout);
    },
    setAbuseWords(state, payload) {
      state.abuseWords = payload || [];
    },
    setOverlay(state, name) {
      state.overlay[name] = !state.overlay[name];
    },
    setUnassignedTicketsModal(state, status) {
      state.unassignedTicketsModal = status;
    },
  },
  actions: {
    handleToolbarNotification(ctx, payload) {
      ctx.commit("setNotification", payload);
    },
    handleOverlay(ctx, name) {
      ctx.commit("setOverlay", name);
    },
    async loadAbuseWords(ctx) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/account/abuse_words`, {
        method: "GET",
        headers: new Headers({
          Authorization: authorization,
        }),
      });
      if (!res.ok) return { status: res.status, statusText: res.statusText };
      const resultData = await res.json();
      ctx.commit("setAbuseWords", resultData);
    },
  },
  getters: {
    toolbarNotification(state) {
      return state.notification;
    },
    showCountryOverlay(state) {
      return state.overlay.country;
    },
    showUnassignedTicketsModal(state) {
      return state.unassignedTicketsModal;
    },
  },
  modules: {
    auth,
    profile,
    events,
    results,
    subscription,
    tropaion,
    tickets,
    teams,
    shop,
    feed,
    cart,
    geocoding,
    checkout,
  },
});
